@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}
:root {
    /* Set CSS variable with tailwind token */
    --button-space: theme('spacing.8');
}
/* html {
    -ms-scroll-snap-type: mandatory;
        scroll-snap-type: mandatory;
    -ms-scroll-snap-points-y: repeat(100vh);
        scroll-snap-points-y: repeat(100vh);
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}
.panel {
    scroll-snap-align: start;
    height: 100vh;
    @apply debug-red;
} */
.stars {
    background-image: url('./images/stars.png?as=webp&quality=75');
    background-size: 1000px auto;
}
.cls-1 {
    fill: #b6806b;
}
.cls-2 {
    fill: #bebec6;
}
.sweet-sans,
body,
.font-sans {
    font-family: 'sweet-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    @apply antialiased;
}
.sweet-sans-bold,
strong,
b {
    font-family: 'sweet-sans-pro', sans-serif;
    font-weight: 800;
    font-style: normal;
}
.tribute,
em,
i,
.font-serif {
    font-family: 'tribute', serif;
    font-weight: 400;
    font-style: italic;
}
.termina,
.font-display {
    font-family: 'termina', sans-serif;
    font-weight: 400;
    font-style: normal;
}
p em {
    @apply text-2xl text-accent-500;
}
.prose {
    --tw-prose-body: #374151;
    --tw-prose-headings: #111827;
    --tw-prose-lead: #4b5563;
    --tw-prose-links: #111827;
    --tw-prose-bold: #111827;
    --tw-prose-counters: #6b7280;
    --tw-prose-bullets: #d1d5db;
    --tw-prose-hr: #e5e7eb;
    --tw-prose-quotes: #111827;
    --tw-prose-quote-borders: #e5e7eb;
    --tw-prose-captions: #6b7280;
    --tw-prose-code: #111827;
    --tw-prose-pre-code: #e5e7eb;
    --tw-prose-pre-bg: #1f2937;
    --tw-prose-th-borders: #d1d5db;
    --tw-prose-td-borders: #e5e7eb;
    --tw-prose-invert-body: #d1d5db;
    --tw-prose-invert-headings: #fff;
    --tw-prose-invert-lead: #9ca3af;
    --tw-prose-invert-links: #b6806b;
    --tw-prose-invert-bold: #fff;
    --tw-prose-invert-counters: #9ca3af;
    --tw-prose-invert-bullets: #b6806b;
    --tw-prose-invert-hr: #374151;
    --tw-prose-invert-quotes: #f3f4f6;
    --tw-prose-invert-quote-borders: #374151;
    --tw-prose-invert-captions: #9ca3af;
    --tw-prose-invert-code: #fff;
    --tw-prose-invert-pre-code: #d1d5db;
    --tw-prose-invert-pre-bg: #00000080;
    --tw-prose-invert-th-borders: #4b5563;
    --tw-prose-invert-td-borders: #374151;
}

@layer utilities {
    .container-bleed,
    .container-bleed.swiper {
		width: calc((var(--current-screen) - var(--container-padding)) + (var(--screen-width) - var(--current-screen)) / 2);
    }
    .prose p {
        /* @apply mb-[1rem] text-lg; */
        @apply [&:not(:last-child)]:mb-[1rem] text-lg;
    }

    #headlessui-portal-root {
        z-index: 100;
        position: relative;
    }
    .stretched-link::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: auto;
        content: '';
        background-color: rgba(0, 0, 0, 0);
    }
    .btn {
        @apply px-4 pb-1.5 pt-2
        outline-none
        no-underline
        rounded-md
        cursor-pointer
        duration-200
        active:hover:ring-2
        active:hover:ring-offset-2
        focus:ring-2
        focus:ring-offset-2
        focus:ring-foreground
        dark:focus:ring-background-400
        dark:focus:ring-offset-background
        focus:ring-offset-background
        inline-flex items-center
		font-display uppercase tracking-widest;
    }
	.btn.longtext {
		@apply normal-case tracking-normal font-sans px-4 pt-1.5 pb-2
	}
    .btn.btn-primary {
        @apply text-background
		bg-foreground
		hover:bg-foreground-200
		active:hover:bg-foreground-100
		active:hover:ring-foreground
		active:hover:ring-offset-background-500;
    }
    .btn.btn-secondary {
        @apply text-background
		bg-background-100
		hover:bg-background-100
		active:hover:bg-background-200
		active:hover:ring-offset-background-700
		active:hover:ring-background-500
		dark:text-foreground
		dark:bg-background-500
		dark:hover:bg-background-600
		dark:active:hover:bg-background-500;
    }
    .btn.btn-accent {
        @apply text-background-900
		bg-accent
		hover:bg-accent-600
		active:hover:bg-accent
		active:hover:ring-accent
		active:hover:ring-offset-background;
    }
}

/* body {
  margin: 0;
  padding: 0;
  height: 900vh;

  transition: background-color 1s ease;
}
.panelCon {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99990;
}
.panel {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
}
.disable {
  position: static;
}
#switch-btn .green {
  background: green;
}
#pane-09 {
  align-items: flex-end;
} */

.panel {
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@layer utilities {
    .fluid-base {
        --fluid-line-height: 1.6;
    }
    /* PROSE: */
    .fluid-prose h1 {
        @apply fluid-5xl mb-0;
    }
    .fluid-prose h2 {
        @apply fluid-4xl mb-0;
    }
    .fluid-prose h3 {
        @apply fluid-3xl mb-0;
    }
    .fluid-prose h4 {
        @apply fluid-2xl mb-0;
    }
    .fluid-prose h5 {
        @apply fluid-xl mb-0;
    }
    .fluid-prose h6 {
        @apply fluid-lg mb-0;
    }

    .ui-m-right {
        margin-right: var(--button-space);
    }
    .ui-p-right {
        padding-right: var(--button-space);
    }
    .ui-w-right {
        max-width: calc(100% - var(--button-space));
    }
    .ui-m-right-0,
    .ui-p-right-0,
    .ui-w-right-0 {
        --button-space: 0;
    }
    .floater-element:hover .hover-image {
        opacity: 1;
    }
/*

    .debug-red {
        position: relative;
    }
    .debug-red:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        box-shadow: 0 0 0 3px inset #ff0000;

    }
    .debug-gray {
        box-shadow: 0 0 0 3px inset #666;
    }
    .debug-green {
        box-shadow: 0 0 0 3px inset green;
    }
    .debug-blue {
        box-shadow: 0 0 0 3px inset blue;
    }
    .debug-yellow {
        box-shadow: 0 0 0 3px inset #ffff00;
    }
    .debug-pink {
        box-shadow: 0 0 0 3px inset pink;
    }
    .debug-purple {
        box-shadow: 0 0 0 3px inset purple;
    }
    .debug-gray {
        box-shadow: 0 0 0 3px inset gray;
    }
    .debug-black {
        box-shadow: 0 0 0 3px inset black;
    }
    .debug-white {
        box-shadow: 0 0 0 3px inset white;
    }
    .debug-orange {
        box-shadow: 0 0 0 3px inset orange;
    }
    .debug-indigo {
        box-shadow: 0 0 0 3px inset indigo;
    }
    .debug-teal {
        box-shadow: 0 0 0 3px inset teal;
    }
    .debug-cyan {
        box-shadow: 0 0 0 3px inset cyan;
    }
    .debug-lime {
        box-shadow: 0 0 0 3px inset lime;
    } */
}
