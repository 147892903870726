@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
	.intro h2, .intro h3  {
		@apply font-serif text-accent font-normal;
	}
    .intro h2 {
        @apply text-5xl mt-5;
    }
    .intro h3 {
        @apply text-4xl mt-4;
    }
}
