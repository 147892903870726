.wp-block-embed__wrapper {
    position: relative;
}

.wp-embed-aspect-16-9 .wp-block-embed__wrapper:before {
    content: '';
    padding-top: 56.25%;
    display: block;
}

.wp-has-aspect-ratio iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.wp-block-embed iframe {
    max-width: 100%;
}

figure {
    margin: 0 0 1rem;
}

.wp-block-image img {
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}
