@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {

    /* Goal of these styles is to keep content returned from WP WYSIWYG fields to be more consistent */

    /** Content Block **/
	.block__content h2, .block__content h3  {
		@apply text-accent tracking-wide mb-5 text-balance;
	}
    .block__content h2 {
        @apply text-3xl xl:text-4xl uppercase;
    }
    .block__content h3 {
        @apply text-2xl xl:text-4xl font-normal;
    }
    .block__content p {
        @apply text-pretty;
    }
    .block__content p:not(:last-of-type) {
        @apply mb-6;
    }

    /** DetailedColumns Block **/
    .block__detailed-columns h2, .block__detailed-columns h3  {
		@apply text-accent font-normal tracking-wide mb-5;
	}
    .block__detailed-columns h2 {
        @apply text-3xl md:text-4xl;
    }
    .block__detailed-columns h3 {
        @apply text-2xl md:text-3xl;
    }
    .block__detailed-columns ul {
        @apply list-none;
    }

    /** Text Image Block **/
	.block__textimage h2, .block__textimage h3  {
		@apply text-accent tracking-wide mb-3 text-balance;
	}
    .block__textimage div.prose h2 {
        @apply text-3xl xl:text-4xl;
    }
    .block__textimage div.prose h3 {
        @apply text-2xl xl:text-3xl font-normal;
    }
    .block__textimage p {
        @apply text-pretty;
    }
    .block__textimage p:not(:last-of-type) {
        @apply mb-6;
    }

    /** Quotes Block **/
    .block__quotes .swiper button {
        @apply bg-transparent;
    }
    .block__quotes .swiper button svg {
        @apply text-black;
    }


    /** Point Person Block **/
    .block__point-person p {
        @apply text-pretty;
    }
}
